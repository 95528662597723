@import "../../../styles/utils";
.menuItem {
  @include font($white, 400, 1.375rem, null, null, 1rem, null, $lightGrey);
}
.fakeMenuItem {
  @include font($white, 400, 1.375rem, null, null, 1rem);
}
.footerMenuItem {
  @include font($white, 400, 1.375rem, null, null, null, null, $lightGrey);
}
.fakeFooterMenuItem {
  @include font($white, 400, 1.375rem);
}
.legalMenuItem {
  text-transform: uppercase;
  @include font($lightGrey, 400, 1rem, null, null, 1rem, null, $white);
}
.fakeLegalMenuItem {
  text-transform: uppercase;
  @include font($lightGrey, 400, 1rem);
}
.h1 {
  @include font($white, 400, 5.25rem, null, null, 2rem);
}
.h2 {
  @include font($white, 400, 3rem, null, null, 1.5625rem);
}
.h3 {
  @include font($white, 400, 1.5rem, null, null, 1rem);
}
.tags {
  @include font($lightGrey, 400, 1.5rem, null, null, 1rem);
}
.baseText {
  @include font($white, 400, 1.125rem, 2.16rem, null, 0.875rem);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.75rem;
  }
}
.baseLink {
  @include font($white, 400, 1.125rem, null, null, 0.875rem, null, $lightGrey);
}

/* Titles
--------------------------------------------- */

@font-face {
  font-family: "Montserrat-Black";
  // src: url("/fonts/BarlowCondensed-Bold.woff") format("woff"),
  //   url("/fonts/BarlowCondensed-Bold.otf") format("opentype");
  font-weight: 900;
  src: url("../../public/fonts/Montserrat-Black.ttf") format("truetype");
}
@mixin font_title_black() {
  font-family: "Montserrat-Black", sans-serif;
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat-Bold";
  // src: url("/fonts/Montserrat-Bold.woff") format("woff"),
  //   url("/fonts/Montserrat-Bold.otf") format("opentype");
  font-weight: 700;
  src: url("../../public/fonts/Montserrat-Bold.ttf") format("truetype");
}
@mixin font_title_bold() {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  // src: url("/fonts/Montserrat-SemiBold.woff") format("woff"),
  //   url("/fonts/Montserrat-SemiBold.otf") format("opentype");
  font-weight: 600;
  src: url("../../public/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@mixin font_title_semi_bold() {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat-Medium";
  // src: url("/fonts/Montserrat-Medium.woff") format("woff"),
  //   url("/fonts/Montserrat-Medium.otf") format("opentype");
  font-weight: 500;
  src: url("../../public/fonts/Montserrat-Medium.ttf") format("truetype");
}
@mixin font_title_medium() {
  font-family: "Montserrat-Medium", sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat-Regular";
  // src: url("/fonts/Montserrat-Regular.woff") format("woff"),
  //   url("/fonts/Montserrat-Regular.otf") format("opentype");
  font-weight: 400;
  src: url("../../public/fonts/Montserrat-Regular.ttf") format("truetype");
}
@mixin font_title_regular() {
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: 400;
}

@mixin font_primary() {
  @include font_title_bold();
}

/* Content
--------------------------------------------- */
@font-face {
  font-family: "Lato-Black";
  // src: url("/fonts/Lato-Black.woff") format("woff"),
  //   url("/fonts/Lato-Black.otf") format("opentype");
  font-weight: 800;
  src: url("../../public/fonts/Lato-Black.ttf") format("truetype");
}
@mixin font_content_black() {
  font-family: "Lato-Black", sans-serif;
  font-weight: 800;
}

@font-face {
  font-family: "Lato-Bold";
  // src: url("/fonts/Lato-Bold.woff") format("woff"),
  //   url("/fonts/Lato-Bold.otf") format("opentype");
  font-weight: 700;
  src: url("../../public/fonts/Lato-Bold.ttf") format("truetype");
}
@mixin font_content_bold() {
  font-family: "Lato-Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "Lato-Regular";
  // src: url("/fonts/Lato-Regular.woff") format("woff"),
  //   url("/fonts/Lato-Regular.otf") format("opentype");
  font-weight: 400;
  src: url("../../public/fonts/Lato-Regular.ttf") format("truetype");
}
@mixin font_content_regular() {
  font-family: "Lato-Regular", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "Lato-Italic";
  // src: url("/fonts/Lato-Italic.woff") format("woff"),
  //   url("/fonts/Lato-Italic.otf") format("opentype");
  font-weight: 400;
  src: url("../../public/fonts/Lato-Italic.ttf") format("truetype");
}
@mixin font_content_italic() {
  font-family: "Lato-Italic", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "Lato-Light";
  // src: url("/fonts/Lato-Light.woff") format("woff"),
  //   url("/fonts/Lato-Light.otf") format("opentype");
  font-weight: 300;
  src: url("../../public/fonts/Lato-Light.ttf") format("truetype");
}
@mixin font_content_light() {
  font-family: "Lato-Light", sans-serif;
  font-weight: 300;
}

@mixin font_secondary() {
  @include font_content_regular();
}

@font-face {
  font-family: "Jost-regular" !important;
  src: url("../../public/fonts/Jost-VariableFont_wght.ttf");
}

@mixin main_font_regular() {
  font-family: "Jost-regular", sans-serif !important;
  font-weight: 700;
  font-weight: 400 !important;
}

@font-face {
  font-family: "Jost-italic" !important;
  src: url("../../public/fonts/Jost-Italic-VariableFont_wght.ttf");
}

@mixin main_font_italic() {
  font-family: "Jost-italic", sans-serif !important;
  font-weight: 700;
  font-weight: 300;
}

@font-face {
  font-family: "Jost-bold" !important;
  font-weight: 700;
  src: url("../../public/fonts/Jost-VariableFont_wght.ttf");
}

@mixin main_font_bold() {
  font-family: "Jost-bold", sans-serif !important;
  font-weight: 700;
}

/**
 * @file
 * Base Fonts.
 */

// @font-face {
//   font-family: "Archia";
//   font-weight: 400;
//   src: url("/fonts/archia-regular-webfont.woff2") format("woff2");
// }
@mixin font(
  $color: null,
  $weight: null,
  $size: null,
  $lineHeight: null,
  $letterSpacing: null,
  $mobileSize: null,
  $mobileWeight: null,
  $hoverColor: null,
  $style: null
) {
  @if $weight {
    font-weight: $weight;
  }
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: $color;
  }
  @if $hoverColor {
    transition: color 0.2s;
    &:hover {
      color: $hoverColor;
    }
  }
  @if $lineHeight {
    line-height: $lineHeight;
  }
  @if $letterSpacing {
    letter-spacing: $letterSpacing;
  }
  @if $style {
    font-style: $style;
  }
  @if $mobileWeight {
    @include md {
      font-weight: $mobileWeight;
    }
  }
  @if $mobileSize {
    @include md {
      font-size: $mobileSize;
    }
  }
}

@import "../../styles/utils.scss";

.creditLink {
  margin-left: -80px;
}
.creditCta > *,
.mobilecreditCta > * {
  min-width: 60px;
  color: #ffffffff !important;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
}

.mobilecreditCta {
  width: 90px !important;
  border: none !important;
  margin-bottom: 5px !important;
  margin-left: -3rem;
  background-color: transparent !important;
}
.footerCtaContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 1rem;
}
.footerRightCtaContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 !important;
}

.footerCta {
  background-image: url("../..//background/cta-active.jpg");
  color: #1a1e40 !important;
}

.popinModalBtnContainer {
  display: flex;
  justify-content: space-evenly;
}
.popinModalColorBtn {
  width: 150px;
  height: 150px;
  border: none !important;
  margin: 5rem 0;
  background-color: transparent !important;
}
.creditCta {
  position: absolute;
  bottom: 1vh;
  left: -7rem;
  width: 90px !important;
  border: none !important;
  margin-bottom: 5px !important;
  background-color: transparent !important;
}

.creditCta:hover,
.mobilecreditCta:hover {
  border: none !important;
  margin-bottom: 5px !important;
  background-color: transparent !important;
  color: #ffffffff !important;
}

.footerLink {
  position: absolute;
  right: -2px;
  bottom: 2vh;
  display: flex;
  justify-content: flex-end;

  img {
    height: 25px;
  }
}
@media screen and (max-width: 1350px) {
  footer {
    margin-top: 30px;
  }
  .creditLink {
    min-width: 70px;
  }
  .creditCta {
    bottom: 0;
    left: -80px;
  }
}
@media screen and (max-width: 1000px) {
  .popinModalColorBtn {
    width: 75px;
    height: 75px;
    img {
      width: 100px;
    }
  }
}

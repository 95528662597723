.endScreenContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titleContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

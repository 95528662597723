@import "../../styles/utils.scss";

.sideMenuContainer,
.exportSideMenuContainer {
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.sideMenuContainer {
  justify-content: space-around;
}

.exportSideMenuContainer {
  height: 100%;
  justify-content: center;
}

.selectCanvasTitle {
  background: linear-gradient(222.63deg, #d1b465 0%, #dedbc7 80.61%);
  background-clip: text;
  background-size: 100%;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.selectCanvasSubtitle {
  font-weight: 700;
}

.selectCanvasSubtitle,
.selectCanvasContent {
  font-size: 36px;
  line-height: 46px;
}

.sideMenuFormBtnContainer {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}

.sideMenuInputForm {
  display: flex;
  height: 4rem;
  justify-content: center;

  border: 1px solid #ffffff;
  border-radius: 40px;
  margin-top: 2rem;
  background: #1a1e40;
  text-align: center;
}
.sideMenuFormBtnContainer > button {
  padding: 15px 30px !important;
}

.sideMenuFormBtnContainer > button > span {
  color: #1a1e40;
  font-family: "Jost" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
}
.sideMenuInputFormAlert {
  position: absolute;
}

.congratulationsideMenuContainer {
  height: 80vh;
}
input:focus::placeholder {
  color: transparent;
}
.downloadContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: $breakpoint-xl) {
  .exportSideMenuContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .sideMenuContainer {
    flex-direction: row;
    align-items: flex-end;
    padding: 1rem 0 2rem 0;
  }
}

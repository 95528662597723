@import "../../styles/utils.scss";

.drawRightContainer {
  display: flex;
  overflow: hidden;
  width: 100vh;
  //max-height: 72.5vh;
  flex-direction: column;
  padding: 0 !important;
  aspect-ratio: 1.7;
}

.drawerh2 {
  display: flex;
  font-size: 72px;
  font-weight: 600;
  line-height: 70px;
}

h5 {
  padding-left: 2.2px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.9px;
  line-height: 45px;
}

.refreshBtn {
  display: flex;
  width: 65.5px;
  height: 65.5px;
  align-items: center;
  justify-content: center;
}

.redrawButton {
  position: absolute;
  z-index: 2;
  // min-width: 270px;
  margin-top: 20px;
}
.drawerIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
}
.toolsBtnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -40px !important;
  // transform: translate(-50%, -50%);
}
.toolBtn {
  position: relative;
  margin: 0 0.5rem;
}

.popinModalColorBtn {
  width: 150px;
  height: 150px;
  border: none !important;
  margin: 2rem 0;
  background-color: transparent !important;
}

.canvasContainer {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 775px;
  height: 100%;
  align-items: center;
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;

  object-fit: cover;
}
@media screen and (min-width: 1750px) {
  .toolsBtnContainer {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 1750px) {
  footer {
    margin-top: 45px;
  }
  .toolsBtnContainer {
    top: -10px;
    display: flex;
  }
  .toolBtn {
    height: 100px;
    aspect-ratio: 1;
  }
  .toolBtn > img {
    height: 40px;
    aspect-ratio: 1;
  }

  .refreshBtn > img {
    height: 15px;
    aspect-ratio: 1;
  }
}
@media screen and (min-width: 950px) and (max-width: 1410px) {
  .refreshBtn {
    margin-left: 6rem;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .refreshBtn {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 950px) {
  .drawerIconContainer {
    justify-content: center;
  }
  .redrawButton {
    width: 37px;
    height: 37px;
    margin-bottom: 10px;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}
@media screen and (min-width: 951px) and (max-width: 1510px) {
  .redrawButton {
    width: 200px;
  }
}

@media screen and (min-width: 1410px) and (max-width: 1510px) {
  .redrawButton {
    min-width: 300px;
  }
}
@media screen and (max-width: 1410px) {
  footer {
    margin-top: 45px;
  }
  .toolsBtnContainer {
    top: -10px;
    display: flex;
    margin-top: -20px !important;
  }
  .toolBtn {
    height: 60px;
    aspect-ratio: 1;
  }
  .toolBtn > img {
    height: 30px;
    aspect-ratio: 1;
  }

  .refreshBtn {
    width: 37px;
    height: 37px;
  }

  .refreshBtn > img {
    height: 15px;
    aspect-ratio: 1;
  }

  .redrawButton > span {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 1181px) {
  footer {
    margin-top: 45px;
  }
  .toolsBtnContainer {
    top: -10px;
    display: flex;
    justify-content: space-around;
  }
  .toolBtn {
    height: 65px;
    aspect-ratio: 1;
  }
  .toolBtn > img {
    height: 40px;
    aspect-ratio: 1;
  }

  .refreshBtn {
    width: 37px;
    height: 37px;
  }

  .refreshBtn > img {
    height: 15px;
    aspect-ratio: 1;
  }
}

.tapeCanvasHide {
  opacity: 0;
}

.tapeCanvasVisible {
  opacity: 1;
}

.popinModalColorBtn > img {
  width: 120px !important;
  height: 120px !important;
}

@media screen and (max-width: 1000px) {
  .popinModalColorBtn {
    width: 75px;
    height: 75px;
    img {
      width: 75px !important;
      height: 75px !important;
    }
  }
}

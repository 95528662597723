@import "../../styles/utils.scss";

.videoContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  background-color: black;
}
.videoPlayer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.videoCta {
  position: absolute;
  bottom: 5rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 8rem !important;
}

@media screen and (max-width: $breakpoint-xl) {
  .videoCta {
    position: absolute;
    right: -7rem;
    bottom: 1rem;
  }
}

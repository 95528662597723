.fill {
  object-fit: cover;
}
.responsive {
  width: 100%;
  height: auto;
}
.imgWrapper {
  position: relative;
}

.pictureMainContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.pictureContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.downloadContainer {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.picture {
  width: 80%;
  border: 10px solid #ffffff;
}

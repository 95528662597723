.miniCanvas {
  width: 100%;
  filter: brightness(50%);
}
.selectedMiniCanvas {
  width: 100%;
  filter: none;
}

.minicanvasBtn {
  border: 1px solid transparent !important;
  background: transparent !important;
  outline: none !important;
}

.minicanvasBtn:hover {
  border: 1px solid transparent !important;
  background: transparent !important;
  outline: none !important;
}

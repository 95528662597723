@import "styles/utils.scss";

.sampleModalContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin-right: 0 !important;
  margin-left: 0 !important;

  transform: scale(0.9) !important;
}
.sampleModalContainer > * {
  width: 100vw !important;
}

.sampleModalBody {
  width: 100vw;
}
.sampleModalIllustration {
  width: 100%;
  height: 100%;
}
.sampleModalCloseBtnContainer {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.picturesSampleBtnIllustration {
  width: 248.75px;
  aspect-ratio: 1.61;
  object-fit: cover;
}

.sampleModalBtn {
  display: flex !important;
  width: 248.75px;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
.picturesSampleBtn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
}
.picturesSampleBtn > img {
  margin: 0 10px;
}

.picturesSampleBtn > * {
  padding: 0;
  color: #ffffff !important;
}

.sampleModalBtn:hover {
  background-color: transparent !important;
  color: #ffffff !important;
}
.closeModalBtn {
  display: flex;
  width: 76.5px;
  height: 76.5px;
  align-items: center;
  justify-content: center;
}

.modal.fade .modal-dialog {
  display: block !important;
  opacity: 0 !important;
  transform: scale(0.9) !important;
  transition: all 0.2s linear !important;
}

.modal.show .modal-dialog {
  opacity: 1 !important;
  transform: scale(1) !important;
}

@media screen and (max-width: $breakpoint-xxl) {
  .picturesSampleBtnIllustration {
    width: 40vh;
    max-width: 200px;
  }

  .sampleModalBtn {
    display: flex;
    justify-content: flex-start;
    // margin-top: 7vh;
  }
}

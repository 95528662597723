@use "sass:math";

$primary: #3b5a65;
$secondary: #cbb7a8;
$white: #ffffff;
$black: #000000;
$darkGrey: #1a1a1a;
$lightGrey: #d7d7d7;
$red: #d40000;
$green: #04cb1c;

$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$black-weight: 900;

$container-width: 1180px;
$breakpoint-sm: 576px;

$breakpoint-md: 768px;

$breakpoint-lg: 992px;

$breakpoint-xl: 1200px;

$breakpoint-xxl: 1400px;

$browser-context: 16; // Default

$portrait: portrait;

@mixin mediaQuery($breakpoint) {
  @media screen and not (min-width: $breakpoint) {
    @content;
  }
  @media screen and (max-width: $breakpoint - 1) {
    @content;
  }
}

@mixin sm {
  @include mediaQuery($breakpoint-sm) {
    @content;
  }
}
@mixin md {
  @include mediaQuery($breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @include mediaQuery($breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @include mediaQuery($breakpoint-xl) {
    @content;
  }
}
@mixin xxl {
  @include mediaQuery($breakpoint-xxl) {
    @content;
  }
}

$moduleMarginValue: 4.06rem;
$moduleMobileMarginValue: 2.03rem;

$baseMarginValue: 1.75rem;
$baseMobileMarginValue: 1.25rem;

@mixin moduleMargin {
  margin-top: $moduleMarginValue;
  margin-bottom: $moduleMarginValue;
  @include md {
    margin-top: $moduleMobileMarginValue;
    margin-bottom: $moduleMobileMarginValue;
  }
}

@function rem($pixels, $context: $browser-context) {
  @return #{math.div($pixels, $context)}rem;
}

@import "../../../styles/utils";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  background-color: $primary;
  color: $white;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
  &.reverted {
    //empty
  }

  &:hover {
    background-color: $secondary;
    transition: 0.3s;
  }
}

.img {
  width: 1rem;

  &.isRight {
    margin-left: 0.75rem;
  }

  &.isLeft {
    margin-right: 0.75rem;
  }
}

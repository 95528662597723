@import "../../styles/utils.scss";

.mobileGlobalContainer {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
}
.mobileMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  margin: 0;
}
.mobileTitleContainer {
  text-align: center;
}
.mobileCtaContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0 3rem 0 !important;

  img {
    height: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.headerCtaContainer {
  position: absolute;
  top: 1rem;
  right: 5px;
  display: flex;
  * {
    width: 4rem !important;
    padding: 3px !important;
    margin: 0 0.5rem;
  }

  .isActive {
    background-image: url("../../background/cta-active.jpg");
    color: #1a1e40 !important;
  }
}

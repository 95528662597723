@import "/src//styles/utils.scss";

[type="button"] {
  display: flex !important;
  align-items: center;
  border-color: rgba(209, 180, 101, 1) !important;
  background-color: #1a1e40 !important;
}
[type="button"] > * {
  @include main_font_regular();
}

.isActive {
  background-image: url("../../../background/cta-active.jpg");
  color: #1a1e40 !important;
}

[type="button"]:hover {
  background-color: #ffffff !important;
  color: #1a1e40 !important;
}

.reverse {
  flex-direction: row-reverse !important;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px !important;
  border-radius: 100px !important;
}
.circularBtn {
  position: relative;
  display: flex;
  height: 116px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50% !important;
  aspect-ratio: 1/1;
}

.circularBtnLabel {
  @include main_font_regular();
  position: absolute;
  bottom: -4vh;
  left: 0%;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
}

.picture {
  margin: 0 15px;
}

@media screen and (min-width: 1400px) and (max-width: 1750px) {
  .circularBtnLabel {
    bottom: -7vh;
    margin-left: 0 !important;
  }
  .cta {
    padding: 10px !important;
  }
  .picture {
    margin: 0 5px 0 0;
  }
}

@media screen and (min-width: 1751px) {
  .circularBtnLabel {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1399px) {
  [type="button"] > * {
    font-size: 0.7em;
  }

  .circularBtnLabel {
    position: absolute;
    top: 100%;
  }
  .cta {
    padding: 10px !important;
  }
  .picture {
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
  }
}

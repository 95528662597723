.homeMainContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 8vh;
}

.homeTitleContainer {
  display: flex;
  justify-content: center;
}
.logo {
  width: 70%;
}

.homeBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 3rem !important;
}

.homeBtnContainer [type="button"] {
  margin: 1rem;
}

.globalContainer,
.globalContainerDark {
  position: fixed;
  box-sizing: border-box;
  padding: 0;
  background-repeat: no-repeat;
  background-size: auto;
  -ms-overflow-style: none;

  overflow-y: scroll;
  scrollbar-width: none;
}
.globalContainer::-webkit-scrollbar,
.globalContainerDark::-webkit-scrollbar {
  display: none;
  appearance: none;
}

.globalContainer {
  background-image: url("../../background/body-bg.jpg");
  background-size: cover;
}
.globalContainerScrollbar {
  overflow-y: hidden;
}

.globalContainerHq {
  background-image: url("../../background/body-bgHq.png");
}
.footerContainer {
  z-index: 10;
  margin: 0 !important;
}
.blurBackground {
  filter: blur(4px) !important;
}

.globalContainerDark {
  height: 100%;

  background-image: linear-gradient(
      90deg,
      rgba(3, 10, 30, 0.6) 63.9%,
      rgba(3, 10, 30, 0) 100%
    ),
    url("../../background/body-bg.jpg");
  background-size: cover;
}

.globalContainerDarkHq {
  height: 100vh;

  background-image: linear-gradient(
      90deg,
      rgba(3, 10, 30, 0.6) 63.9%,
      rgba(3, 10, 30, 0) 100%
    ),
    url("../../background/body-bgHq.png");
}

@media screen and (max-width: 850px) {
  .mainContainerRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

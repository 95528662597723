.introTitleContainer {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}
.introTitle {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1180px) {
  .introTitle {
    font-size: 1.5em;
  }
}

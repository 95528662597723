@import "../../styles/utils.scss";

.mobileMainContainer {
  margin-bottom: 8rem;
}

.mobileNavbarsocialsLink {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;

  img {
    height: 20px;
  }
}
.mobileTitleContainer {
  margin-top: 1.5rem;
}

.screenSaverVideoContainer {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
}

.screenSaverVideoContainer > * {
  height: 100%;
}

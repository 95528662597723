@import "../../styles/utils.scss";

.stepperContainer {
  display: flex;
}
.stepperContainerWebDisplay {
  margin-bottom: 2rem;
}
.stepperLine {
  position: relative;
  z-index: -1;
  display: flex;
  height: 285px;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  border-left: 1px solid #ffffff;
}

.stepContainer {
  margin-top: -18px;
  margin-left: 2rem;
}
.details {
  height: 0;
}

.stepRound::after {
  position: absolute;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffffff;
  content: "";
  transition: all 0.5s;
}

.stepRoundActive::before {
  position: absolute;
  left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(222.63deg, #d1b465 0%, #dedbc7 80.61%);
  content: "";
}

.step {
  display: flex;
  margin-left: 1rem;
}

.stepNumber,
.stepDescription {
  user-select: none;
  @include main_font_regular();
}

.stepNumber {
  background: linear-gradient(222.63deg, #d1b465 0%, #dedbc7 80.61%);
  background-clip: text;
  background-size: 100%;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.stepDescription {
  font-size: 24px;
  line-height: 35px;
}

@media screen and (max-width: $breakpoint-xxl) {
  .stepNumber {
    font-size: 2rem;
    line-height: 46px;
  }
  .stepDescription {
    font-size: 18px;
    line-height: 26px;
  }
  .Stepper_stepDescription__8xd7L {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .stepperLine {
    height: 20vh;
  }
  .stepperContainerWebDisplay {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .stepperContainerWebDisplay {
    margin-bottom: 5rem;
  }
}

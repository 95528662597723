@import "styles/utils.scss";

header {
  z-index: 10;
  padding: 35px 0 35px 0;
}

.headerContainer {
  display: flex;
  width: 100%;
}

.logo {
  width: 335px;
  height: 56px;
  padding-top: 10px;
  margin-left: -115px;
}
.headerLogoContainer {
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerNavbarContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -6vw;
}

.navbar-brand {
  display: flex;
}

.headerNavbar,
.headerNavbarsocialsLink {
  display: flex;
  align-items: flex-end;
}
.headerNavbarsocialsLink {
  padding-bottom: 8px;
}
.navItem {
  padding: 0 1rem;
}
@media screen and (max-width: $breakpoint-xl) {
  header {
    padding: 20px 0 20px 0;
  }
  .navItem {
    padding: 0;
  }

  .logo {
    width: 311px;
    height: 50px;
    margin-left: -32px;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 8vh;
  }
}

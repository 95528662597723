.homeTitleContainer {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
}

.homeTitle,
.spanTitle {
  font-family: "Jost" !important;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 69px;
}

.spanTitle {
  font-weight: 400;
}

.homeCTA {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

@import "../../styles/variables";

.GDPROverlay {
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);

  pointer-events: none;
}

.GDPRButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  @include lg {
    flex-direction: column;
    align-items: center;
  }
  button {
    margin: 0.5em;
  }
}

.GDPRMain {
  position: fixed;
  z-index: 1999;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 1rem;
  background: $white;
  @include lg {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 2rem;

    button {
      font-size: 0.6rem;
    }
  }

  .GDPRSkip {
    position: absolute;
    top: 1rem;
    right: 2rem;
    @include lg {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: 1rem;
    }
    .GDPRSkipCTA {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
      &:hover {
        span {
          color: #1a1e40 !important;
        }
      }
    }
  }
  .GDPRLeft {
    width: 65%;
    @include lg {
      width: 100%;
    }
    * {
      color: $black;
    }
  }
  .GDPRLink {
    text-decoration: underline;
    a {
      font-size: 0.75rem;
    }
  }
  .GDPRRight {
    display: flex;
    width: 35%;
    @include lg {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .GDPRButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 2rem;
      @include lg {
        flex-direction: row;
        align-items: center;
      }
      button {
        max-height: 3rem;
        margin: 0.5em;
      }
    }
  }
}

.GDPRWrapper {
  position: fixed;
  z-index: 5001;
  top: 50%;
  left: 50%;
  display: flex;
  min-height: 20rem;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5em 2em;
  border: 1px solid $black;
  margin: auto;
  background: $white;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  @include lg {
    width: 90%;
    height: 90%;
    font-size: 0.8em;
    overflow-y: scroll;
    p {
      font-size: 1em;
      line-height: 1.4em;
    }
  }
  p {
    margin: 0.75rem 0;
    line-height: 1rem;
  }

  .GDPRDetails {
    max-height: 30rem;
    padding: 0.5em 1em;
    overflow-y: scroll;
    * {
      color: black;
    }
    h2 {
      margin: 0.75em 0 0.75em;
    }
    h3 {
      margin: 0.75em 0 0.75em;
    }
    h4 {
      margin: 0.75em 0 0.75em;
    }
    h5 {
      margin: 0.75em 0 0.75em;
    }
    .smallText {
      font-size: 0.7em;
      line-height: 1.25em;
    }

    .cookiesList {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.75rem 0;
        font-size: 0.8em;
        font-weight: 700;
      }
    }
  }
}

.onoff {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  margin-left: -2rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 2rem;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  input[type="checkbox"],
  input[type="radio"] {
    width: 0;
    height: 0;
  }
}

.onoff label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4) inset;
  cursor: pointer;
  font-weight: 700;
  text-indent: 2rem;
  transition: background 0.3s, text-indent 0.3s;
  &.btnUnselected {
    background: $red;
  }
  &.btnSelected {
    background: $green;
  }
}

.onoff label:after {
  position: absolute;
  z-index: 1;
  left: 0.5rem;
  display: block;
  width: 100%;
  color: $white;
  content: "✖";
  font-size: 1rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.35);
}
.onoff label:before {
  position: absolute;
  z-index: 2;
  top: 0.125rem;
  left: 0.125rem;
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 16px;
  background: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  content: "";
  transition: left 0.3s;
}
.onoff input:checked + label {
  text-indent: 0.3rem;
}
.onoff input:checked + label:after {
  color: $black;
  content: "✓";
}
.onoff input:checked + label:before {
  left: 2.1rem;
}

.editGDPRBtn,
.drawEditGDPRBtn {
  position: absolute;
  z-index: 2;

  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 0.7rem;
}
.editGDPRBtn {
  right: 0;
  bottom: -0.5rem;
}
.drawEditGDPRBtn {
  right: -9.3vw;
  bottom: 1vh;
  @include xl {
    right: -95px;
    bottom: 0;
    font-size: 0.6rem;
  }
  // @include lg {
  //   right: -80px;
  //   font-size: 0.5rem;
  // }
}

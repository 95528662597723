@import "./utils.scss";

html,
body,
body > header {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  overflow: hidden;
  @include main_font_regular(); // opacity: 0.2;
  margin: 0;
  color: #ffffffff;
  overscroll-behavior: contain;
}

h2 {
  @include main_font_bold();
}

h3,
p {
  @include main_font_regular();
}
.legend {
  @include main_font_italic();
}

.hg-theme-default .hg-button {
  color: black;
}
.react-simple-keyboard {
  position: absolute;
  right: 0;
  bottom: 4vh;
  left: 20%;
  width: 50vw;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
a {
  text-decoration: none;
}

.rotateWood {
  transform: rotate(90deg) !important;
}
@media screen and (max-width: $breakpoint-xl) {
  span {
    font-size: 0.6rem !important;
  }
}

@import "../../styles/utils.scss";

.mobileGlobalContainer {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  margin: auto;

  @media screen and (max-width: 930px) {
    & p {
      font-size: 0.8rem;
    }
  }
}
.mobileMainContainer {
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh !important;
}
.mobileTitleContainer {
  text-align: center;
}
.mobileCtaContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    height: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.headerCtaContainer {
  position: absolute;
  top: 2rem;
  right: 5px;
  display: flex;
  * {
    width: 4rem !important;
    padding: 3px !important;
    margin: 0 0.5rem;
  }

  .isActive {
    background-image: url("../../background/cta-active.jpg");
    color: #1a1e40 !important;
  }
}

@media screen and (max-height: 400px) {
  .mobileMainContainer {
    height: 100px;
  }
}

@import "styles/utils.scss";

.popinModalContainer {
  color: #1a1e40;
  transform: scale(0.9) !important;
}

.popinModalCloseBtn,
.popinmodalTitle,
.popinModalFooterBtnContainer {
  display: flex;
}
.popinmodalTitle {
  justify-content: center;
  line-height: 69px;
}

.popinModalCloseBtn {
  height: 76.5px;
  padding: 20px !important;
  aspect-ratio: 1;
}
.popinModalCloseBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popinModalContent {
  font-size: 1.5em;
  text-align: center;
}
.popinModalFooterBtnContainer {
  align-items: center;
  justify-content: center;
}
.popinModalColorContainer > * {
  display: flex;
  justify-content: space-between;
  color: #ffffffff;
}
.poppinModalFooterCta {
  display: flex;
  align-items: center;
  margin: 5vh;
  background-image: url("../..//background/cta-active.jpg");
  color: #1a1e40 !important;
}

.popinModalColorBtn {
  background-color: none !important;
}

.creditModalContainer {
  width: 100%;
  max-height: 80vh;
  padding-left: 9rem;
  object-fit: cover;
  overflow-y: scroll;

  span {
    font-size: 1rem !important;
  }

  h3 {
    font-size: 1.25rem;
  }
}
.creditModalContent {
  h3 {
    display: contents;
    font-size: 1rem !important;
    font-style: italic;
    font-weight: 700 !important;
  }
}

.creditModalContributionContainerRightContainer,
.creditModalContributionContainerLeftContainer {
  margin-top: 5rem !important;
}
.creditModalContributionContainerRightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.creditModalContributionContainerLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.creditModalContributionContainer {
  display: flex;
}
.creditModalContributionLegend {
  margin-top: 10px;
}
.creditModalSquarePicture {
  width: 100px;
  aspect-ratio: 1/1;
}
.creditModalPicture {
  width: 200px;
}
.creditModalTitle {
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .popinmodalTitle {
    font-size: 1.5em;
  }
  .popinModalContent {
    font-size: 1.2em;
  }

  .creditModalContainer {
    padding-left: 0;
  }
  .creditModalContainer * {
    text-align: center;
  }
  .creditModalContributionContainerLeftContainer {
    justify-content: center;
    margin: 2rem 0 0.5rem 0 !important;
  }
  .creditModalContributionContainerRightContainer {
    justify-content: center;
    margin-top: 1rem !important;
  }
}
